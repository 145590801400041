var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"add-price"},[_c('el-form-item',{attrs:{"label":"商品规格","required":""}},[_c('el-radio-group',{model:{value:(_vm.value.spec_type),callback:function ($$v) {_vm.$set(_vm.value, "spec_type", $$v)},expression:"value.spec_type"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("单规格")]),_c('el-radio',{attrs:{"label":2}},[_vm._v("多规格")])],1)],1),_c('specification',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.spec_type == 2),expression:"value.spec_type == 2"}],model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.spec_type == 1),expression:"value.spec_type == 1"}]},[_c('el-form-item',{attrs:{"label":"零售价","required":"","prop":"spec_value_list[0].sell_price","rules":[
        {
          required: true,
          message: '请输入零售价',
          trigger: ['blur', 'change'],
        },
      ]}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.specs_single.sell_price),callback:function ($$v) {_vm.$set(_vm.value.specs_single, "sell_price", $$v)},expression:"value.specs_single.sell_price"}},[_c('template',{slot:"append"},[_vm._v("元")])],2)],1),_c('el-form-item',{attrs:{"label":"工厂价","required":"","prop":"spec_value_list[0].factory_price","rules":[
        {
          required: true,
          message: '请输入工厂价',
          trigger: ['blur', 'change'],
        },
      ]}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.specs_single.factory_price),callback:function ($$v) {_vm.$set(_vm.value.specs_single, "factory_price", $$v)},expression:"value.specs_single.factory_price"}},[_c('template',{slot:"append"},[_vm._v("元")])],2)],1),_c('el-form-item',{attrs:{"label":"体积","required":"","rules":[
        {
          required: true,
          message: '请输入体积',
          trigger: ['blur', 'change'],
        },
      ]}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.specs_single.volume),callback:function ($$v) {_vm.$set(_vm.value.specs_single, "volume", $$v)},expression:"value.specs_single.volume"}})],1),_c('el-form-item',{attrs:{"label":"重量(g)","required":"","rules":[
        {
          required: true,
          message: '请输入重量',
          trigger: ['blur', 'change'],
        },
      ]}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.specs_single.weight),callback:function ($$v) {_vm.$set(_vm.value.specs_single, "weight", $$v)},expression:"value.specs_single.weight"}})],1),_c('el-form-item',{attrs:{"label":"长","required":"","prop":"spec_value_list[0].length","rules":[
        {
          required: true,
          message: '请输入长',
          trigger: ['blur', 'change'],
        },
      ]}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.specs_single.length),callback:function ($$v) {_vm.$set(_vm.value.specs_single, "length", $$v)},expression:"value.specs_single.length"}})],1),_c('el-form-item',{attrs:{"label":"宽","required":"","prop":"spec_value_list[0].width","rules":[
        {
          required: true,
          message: '请输入宽',
          trigger: ['blur', 'change'],
        },
      ]}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.specs_single.width),callback:function ($$v) {_vm.$set(_vm.value.specs_single, "width", $$v)},expression:"value.specs_single.width"}})],1),_c('el-form-item',{attrs:{"label":"高","required":"","prop":"spec_value_list[0].high","rules":[
        {
          required: true,
          message: '请输入高',
          trigger: ['blur', 'change'],
        },
      ]}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.specs_single.high),callback:function ($$v) {_vm.$set(_vm.value.specs_single, "high", $$v)},expression:"value.specs_single.high"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }