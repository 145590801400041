
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import MaterialSelect from "@/components/material-select/index.vue";
import PopoverInput from "@/components/popover-input.vue";
import { flatten } from "@/utils/util";
@Component({
    components: {
        MaterialSelect,
        PopoverInput,
    },
})
export default class Specification extends Vue {
    $refs!: { materialSelect: any };
    @Prop() value: any;
    tableDataIndex = 0;
    fileList = [];
    tableData: any[] = [];
    selectData: any[] = [];
    lang: any[] = [];
    get sepcItem() {
        this.value.spec_value.forEach((v: any) => {
            if (typeof v.name === 'object') {
                this.lang = Object.keys(v.name);
            }
        })
        return this.value.spec_value;
    }

    get disabledBatchBtn() {
        return !this.selectData.length;
    }

    @Watch("value.spec_value_list")
    defaultDataChange(val: any[]) {
        this.tableData = val;
    }

    @Watch("sepcItem", { deep: true, immediate: true })
    sepcItemChange(val: any) {
        this.setTableData();
    }

    @Watch("tableData", { deep: true })
    tableDataChange(val: any) {
        this.value.spec_value_list = val;
    }

    // 多语言设置label
    labelItemName(item: any) {
        if (this.lang.length > 1) {
            let labelName: any = []
            for (let key in this.lang) {
                if (item) labelName.push(item[this.lang[key]]);
            }
            return /^,+$/.test(labelName) ? '' : labelName.join(',')
        } else {
            return item
        }
    }

    // 添加规格项
    addSpecItem() {
        if (this.sepcItem.length >= 3) {
            return
        }
        let multiLanguage: any = {};

        for (const item of this.lang) {
            this.$set(multiLanguage, item, '');
        }
        this.sepcItem.push({
            has_image: false,
            id: '',
            name: this.lang.length > 1 ? Object.assign({}, multiLanguage) : '',
            spec_list: [
                {
                    id: '',
                    value: this.lang.length > 1 ? Object.assign({}, multiLanguage) : '',
                    image: '',
                }
            ]
        })
    }

    // 移除规格项
    removeSpecItem(index: number) {
        if (this.sepcItem.length <= 1) {
            return this.$message.error("至少有一个规格项");
        }
        this.sepcItem.splice(index, 1);
    }

    // 添加规格值
    addSpecValue(index: number) {
        let multiLanguage: any = {};

        for (const item of this.lang) {
            this.$set(multiLanguage, item, '');
        }
        this.sepcItem[index].spec_list.push({
            id: "",
            value: this.lang.length > 1 ? Object.assign({}, multiLanguage) : '',
            image: "",
        });
    }

    // 移除规格值
    removeSpecValue(index: number, sindex: number) {
        this.sepcItem[index].spec_list.splice(sindex, 1);
    }

    addImage(i: number, v: boolean) {
        this.sepcItem.forEach((item: any, index: number) => {
            item.has_image = false;
            if (i == index) {
                item.has_image = v;
            }
            item.spec_list.forEach((sitem: any) => {
                sitem.image = "";
            });
        });
        this.tableData.forEach((item) => {
            item.image = "";
        });
    }

    checkValue(index: number, sindex: number) {
        const value = this.sepcItem[index].spec_list[sindex].value;
        this.sepcItem[index].spec_list.forEach((item: any, idx: number) => {
            if (item.value && sindex != idx && item.value == value) {
                this.sepcItem[index].spec_list[sindex].value = "";
                this.$message({
                    message: "已存在相同规格值",
                    type: "warning",
                });
            }
        });
    }

    selectDataChange(value: any[]) {
        this.selectData = value.map((item) => item.ids);
    }

    batchSetting(value: string, fields: string) {
        this.tableData.forEach((item) => {
            if (this.selectData.includes(item.ids)) {
                item[fields] != undefined && (item[fields] = value);
            }
        });
    }

    // 设置字段名称
    setFields(prev: any, next: any) {
        let valueArr: any[] = [prev, next];
        valueArr = valueArr.filter((item) => item.value !== undefined);
        const ids = flatten(valueArr.map((item) => item.ids)).join();
        const value = flatten(valueArr.map((item) => item.value));
        return {
            id: prev.id ? prev.id : "",
            ids: ids,
            value,
            spec_value_str: this.lang.length > 1 ? value : value.join(),
            image: prev.image ? prev.image : next.image,
            sell_price: prev.sell_price ? prev.sell_price : 31,
            factory_price: prev.factory_price ? prev.factory_price : 31,
            // lineation_price: prev.lineation_price ? prev.lineation_price : '',
            // cost_price: prev.cost_price ? prev.cost_price : "",
            stock: prev.stock ? prev.stock : 999,
            volume: prev.volume ? prev.volume : 0,
            weight: prev.weight ? prev.weight : 320,
            length: prev.length ? prev.length : 20,
            width: prev.width ? prev.width : 15,
            high: prev.high ? prev.high : 3,
            // bar_code: prev.bar_code ? prev.bar_code : ''
        };
    }

    // 通过规格项和规格值得到一个表格data
    getTableData(arr: any[]) {
        arr = JSON.parse(JSON.stringify(arr));
        return arr.reduce(
            (prev, next) => {
                const newArr: any[] = [];
                for (let i = 0; i < prev.length; i++) {
                    if (!next.length) {
                        newArr.push(this.setFields(prev[i], {}));
                    }
                    for (let j = 0; j < next.length; j++) {
                        next[j].ids = j;
                        newArr.push(this.setFields(prev[i], next[j]));
                    }
                }
                return newArr;
            },
            [{}]
        );
    }

    setTableData() {
        const { tableData, sepcItem } = this
        console.log('this.value.spec_value', this.value.spec_value)
        console.log('sepcItem', sepcItem)
        const specList = sepcItem.map((item: any) => item.spec_list)
        console.log('specList', specList)
        const newData = this.getTableData(specList)
        const rawData = JSON.parse(JSON.stringify(tableData))
        const rawObject: any = {}
        if (this.lang.length > 1) {
            rawData.forEach((item: any) => {
                if (item.spec_value_str !== undefined) {
                    console.log('396--item.spec_value_str', item.spec_value_str)
                    console.log(
                        'item.spec_value_str.includes(},{)',
                        item.spec_value_str.includes('},{') ?
                            this.mergeZhCnValues(JSON.parse(`[${item.spec_value_str}]`)) :
                            item.spec_value_str.includes('{') && item.spec_value_str.includes('}') ?
                                JSON.parse(item.spec_value_str).zh_cn : item.spec_value_str
                    )

                    /**
                     * item.spec_value_str.includes('},{') ?  
                     * 如果数据格式是{zh_cn: 1111,en: 2222},{zh_cn: 1111,en: 2222} 
                     * 调用this.mergeZhCnValues，使用JSON.parse() 遍历里面所有的zh_cn并用,拼成字符串
                     * item.spec_value_str.includes('{') && item.spec_value_str.includes('}') ?   
                     * 如果数据格式是{zh_cn: 1111,en: 2222} 调用JSON.parse()并使用zh_cn
                     * item.spec_value_str 如果数据格式是 1111 直接赋值
                     */

                    rawObject[
                        item.spec_value_str.includes('},{') ?
                            this.mergeZhCnValues(JSON.parse(`[${item.spec_value_str}]`)) :
                            item.spec_value_str.includes('{') && item.spec_value_str.includes('}') ?
                                JSON.parse(item.spec_value_str).zh_cn :
                                item.spec_value_str
                    ] = item
                }
            })
            console.log('rawObject', rawObject)
            console.log('rawData', rawData)
            console.log('newData', newData)
            let tableData: any = [];
            this.tableData = newData.forEach((item: any) => {
                if (item.spec_value_str) {
                    console.log('item', item)
                    console.log('item.spec_value_str', item.spec_value_str)
                    console.log('map----item.spec_value_str', this.mergeZhCnValues(item.spec_value_str))
                    console.log('map----rawObject', rawObject[this.mergeZhCnValues(item.spec_value_str)])
                    console.log('map----rawObject----all', rawObject)
                    rawObject[this.mergeZhCnValues(item.spec_value_str)] ? tableData.push({
                        ...rawObject[this.mergeZhCnValues(item.spec_value_str)],
                        value: item.value,
                        ids: item.ids,
                        image: item.image || rawObject[this.mergeZhCnValues(item.spec_value_str)].image
                    })
                        : tableData.push(item)
                }
            }
            )
            console.log('tableData', tableData)
            this.tableData = tableData;
        } else {
            rawData.forEach((item: any) => {
                if (item.spec_value_str !== undefined) {
                    rawObject[item.spec_value_str] = item
                }
            })
            this.tableData = newData.map((item: any) =>
                rawObject[item.spec_value_str]
                    ? {
                        ...rawObject[item.spec_value_str],
                        value: item.value,
                        ids: item.ids,
                        image: item.image || rawObject[item.spec_value_str].image
                    }
                    : item
            )
        }
    }

    mergeZhCnValues(value: any): string {
        console.log('mergeZhCnValues', value)
        if (value.length === 0) {
            return ''; // 处理空数组情况，返回空字符串
        }
        const zh_cnArray = value.map((obj: any) => obj.zh_cn);
        const mergedString = zh_cnArray.join(',');
        return mergedString;
    }

    addSpecImage(index: number) {
        this.tableDataIndex = index;
        this.$refs.materialSelect.showDialog();
    }

    changeSpecImage(value: string) {
        this.tableData[this.tableDataIndex].image = value;
    }

    removeSpecImage(index: number) {
        this.tableData[index].image = "";
    }
}
